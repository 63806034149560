import React from 'react'
import AdminYoutubeTasks from '../../Components/AdminYoutubeTasks'

const AdminYoutube = () => {
  return (

<>
<AdminYoutubeTasks/>
{/* <Spinner/> */}

</>
  )
}

export default AdminYoutube