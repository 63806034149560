import React from 'react'
import AdminAdverts from '../../Components/AdminAdverts'

const AdminAdvertTasks = () => {
  return (


      <AdminAdverts/>
 
  )
}

export default AdminAdvertTasks