import React from 'react'
import UserManagementPanel from '../../Components/UserManagementPanel'


const Search = () => {
  return (
    <UserManagementPanel/>
  )
}

export default Search